import { RefObject, useEffect, useMemo, useState } from 'react';

export function useIsInViewport(ref: RefObject<any>) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const isClient = typeof window !== 'undefined';

  const observer = useMemo(() => {
    if (isClient) {
      const ioConfiguration = {
        /**
         * This rootMargin creates a horizontal line vertically centered
         * that will help trigger an intersection at that the very point.
         */
        rootMargin: '-50% 0% -50% 0%',

        /**
         * This is the default so you could remove it.
         * I just wanted to leave it here to make it more explicit
         * as this threshold is the only one that works with the above
         * rootMargin
         */
        threshold: 0,
      };
      return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), ioConfiguration);
    }
    return null;
  }, [isClient]);

  useEffect(() => {
    if (typeof ref.current !== 'undefined') {
      observer?.observe(ref.current);
      return () => {
        observer?.disconnect();
      };
    }
    return () => {};
  }, [ref, observer]);

  return isIntersecting;
}
