import React, { FC, ReactNode, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints, NafThemeType } from '@naf/theme';
import { useIsInViewport } from '../../hooks/useIsInViewport';
import { ThemeContextAction } from '../../lib/ThemeContext';
import { BaseContainerProps } from '../../../../types/baseContainerProps';

interface Props {
  theme: NafThemeType | null;
  children?: ReactNode | ReactNode[];
}

export const ColorSection: FC<Props> = ({ theme, children }) => {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const setContextTheme = useContext(ThemeContextAction);

  useEffect(() => {
    if (isInViewport && theme) {
      if (theme) setContextTheme(theme);
    }
  }, [isInViewport, setContextTheme, theme]);

  return (
    <StyledColorSection $isInViewport={isInViewport}>
      <div ref={ref}>{children}</div>
    </StyledColorSection>
  );
};

const StyledColorSection = styled.div<{ $isInViewport: boolean } & BaseContainerProps>`
  width: 100%;

  &:last-child {
    .TextAndImageBlock,
    .ImageLinksBlock,
    .Timeline,
    .NavigationLinksBlock {
      margin-bottom: 0;

      @media (max-width: ${breakpoints.s}) {
        margin-bottom: 0;
      }
    }
  }
`;
